import {StyleExtractor} from "@kubric/utils";
import {graphql} from "gatsby";
import React from "react";

import LastSection from "../../components/AboveFooterSection";
import Buffer from "../../components/Buffer";
import Layout from "../../components/Layout";
import StructuredData from "../../components/StructuredData";
import {AuditBGSVG} from "../../components/pages/audit/AuditBGSVG";
import AuditBookSection from "../../components/pages/audit/AuditBookSection";
import AuditExpertSection from "../../components/pages/audit/AuditExpertSection";
import MMReasons from "../../components/pages/modemagic/MMReasons";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import "../styles.scss";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const AuditPage = ({data}) => {
  const isMobile = useMediaQuery("(max-width: 800px)");
  const seoData = data.allSanitySeoMeta.nodes[0];
  const {heroAuditSection, auditStatsSection, reasons, experts} =
    data.allSanityAuditPage.nodes[0] ?? {};
  const {lastSection} = data.allSanityModemagic3Page.nodes[0] ?? {};

  return (
    <Layout
      type='modemagic'
      className={styler.get("auditPageWrap")}
      mmv3
      seoData={seoData}
      headerProps={{showMMCta: true}}
    >
      <StructuredData />
      <Buffer buffer={81} mobileBuffer={30} />

      {/* Plans Section */}
      <section
        id='auditForm'
        className={styler.get("auditBookWrap", "full-bleed")}
      >
        <AuditBGSVG className={styler.get("bgSvg")} />
        <AuditBookSection data={heroAuditSection} isMobile={isMobile} />
      </section>

      {/* Purple Section */}

      <section className={styler.get("purpleWrapAudit", "full-bleed")}>
        <div className={styler.get("auditStats")}>
          {auditStatsSection.map(({auditStatValue, auditTitleValue}) => (
            <div className={styler.get("auditStatContainer")}>
              <p className={styler.get("auditValue")}>{auditStatValue}</p>
              <p className={styler.get("auditStatText")}>{auditTitleValue}</p>
            </div>
          ))}
        </div>
      </section>

      <MMReasons data={reasons} isMobile={isMobile} hideBorderSVG />

      <AuditExpertSection className={styler.get(["w-full"])} data={experts} />
      {/* Footer Section */}
      <section className={styler.get("footerWrap", "full-bleed")}>
        <LastSection data={lastSection} hideTicketSectionInDesktop />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "audit"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityAuditPage {
      nodes {
        heroAuditSection {
          auditBookContent {
            auditFormCTA
            auditFormFullName
            auditFormStoreURL
            auditFormTitle
            auditFormworkEmail
            auditFormMonthlyTrafficLabel
            auditFormMonthlyTraffic
            auditFormFullNamePlaceHolder
            auditFormStoreURLPlaceholder
            auditFormworkEmailPlaceholder
            auditFormFullNameError
            auditFormStoreURLError
            auditFormworkError
          }
          auditQuizThanksSection {
            auditQuizBeginCTA
            auditThanksLaterCTA
            auditThanksMessage
          }
          heroAuditSection {
            auditHeroTitle
            _rawAuditHeroDescription
            auditSocialProofs {
              image {
                asset {
                  gatsbyImageData
                }
              }
              alt
            }
          }
          auditQuizSection {
            auditQuizHeader
            auditQuizNextCTA
            auditQuiz {
              auditQuestion
              auditQuestionOption
              auditOptions {
                auditAnswerOptionAnswer
                auditAnswerOptionValue
              }
            }
          }
          auditQuizProblem {
            auditQuizHeader
            auditQuizNextCTA
            auditQuiz {
              auditQuestion
              auditQuestionOption
              auditOptions {
                auditAnswerOptionAnswer
                auditAnswerOptionValue
              }
            }
          }
          auditFormThanksSection {
            auditThanksLaterCTA
            auditThanksMessage
            auditQuizBeginCTA
          }
        }
        reasons {
          _rawHeading
          cards {
            description
            heading
            image {
              image {
                asset {
                  gatsbyImageData
                }
              }
            }
            hoveredImage {
              image {
                asset {
                  gatsbyImageData
                }
              }
            }
          }
        }
        auditStatsSection {
          auditStatValue
          auditTitleValue
        }
        experts {
          _rawExpertsDescription
          expertsCTAText
          expertsHeading
          expertsImages {
            expertName
            expertImage {
              image {
                asset {
                  gatsbyImageData
                }
              }
            }
            _rawExpertDescription
          }
          expertsGIF {
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allSanityModemagic3Page: allSanityModemagicPage(
      filter: {page: {eq: "mmv3"}}
    ) {
      nodes {
        midSection {
          _rawText
        }
        lastSection {
          _rawHeading
          _rawFinalHeading
          _rawDescription
          cta
          placeholder
        }
      }
    }
  }
`;

export default AuditPage;
